import React from "react";
import Layout from "../components/Layout";
import Cart from "../components/Cart";

const CartPage = () => (
  <Layout>
    <Cart />
  </Layout>
);

export default CartPage;
